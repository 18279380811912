@import "../../../styles/scss/index.scss";

.container {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0.8rem;
  padding-top: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1355px) {
  .container {
    max-width: 1140px;
  }
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 720px;
  }

  .ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .side-banner-component {
    .side-banner {
      .image-banner {
        width: 640px !important;
        height: 480px !important;
      }
    }
  }

  .side-banner-component {
    .side-banner {
      display: flex !important;
      justify-content: center !important;
      padding-top: 60px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .container {
    max-width: 100%;
  }

  .hindiEnglish {
    justify-content: center !important;
    padding-left: 0px !important;
  }

  .sidebarBanner {
    padding-left: 0px !important;
  }

  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 767) {
  .hindiEnglish {
    justify-content: center;
  }
}


.post-count {
  color: $optional-color;
  font-size: 14px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.widget-area {
  padding-left: 15px;

  .widget {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .widget-title {
      margin-bottom: 25px;
      position: relative;
      padding-bottom: 7px;
      border-bottom: 1px solid #eeeeee;

      font: {
        size: 22px;
        weight: 800;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 50px;
        height: 1px;
        bottom: -1px;
        background-color: $main-color;
      }
    }
  }

  .widget_search {
    form {
      position: relative;

      .screen-reader-text {
        display: none;
      }

      label {
        display: block;
        margin-bottom: 0;
      }

      .search-field {
        height: 50px;
        color: $black-color;
        background-color: #f2f4f5;
        display: block;
        width: 100%;
        border-radius: 5px;
        padding: 0 0 0 15px;
        border: none;
        transition: $transition;

        font: {
          size: $font-size;
          weight: 500;
        }

        &::placeholder {
          color: $optional-color;
          transition: $transition;
        }

        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }

      button {
        border: none;
        background-color: transparent;
        color: $main-color;
        height: 40px;
        width: 40px;
        position: absolute;
        right: 5px;
        padding: 0;
        transition: $transition;
        top: 5px;
        font-size: 20px;

        i {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover,
        &:focus {
          border-radius: 5px;
          background-color: $main-color;
          color: $white-color;
        }
      }
    }
  }

  .widget_edemy_posts_thumb {
    position: relative;
    overflow: hidden;

    .item {
      overflow: hidden;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      .thumb {
        float: left;
        height: 80px;
        overflow: hidden;
        display: block;
        position: relative;
        width: 80px;
        margin-right: 15px;
        z-index: 1;

        .fullimage {
          width: 80px;
          height: 80px;
          display: inline-block;
          border-radius: 5px;

          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }

          &.bg1 {
            background-image: url(/images/blog/blog15.jpg);
          }

          &.bg2 {
            background-image: url(/images/blog/blog14.jpg);
          }

          &.bg3 {
            background-image: url(/images/blog/blog13.jpg);
          }
        }

        &::before,
        &::after {
          transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
          content: "";
          background-color: $white-color;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
        }

        &::before {
          width: 40px;
          height: 1px;
          left: 100%;
        }

        &::after {
          height: 40px;
          width: 1px;
          top: 0;
        }
      }

      .info {
        overflow: hidden;
        margin-top: 5px;

        span {
          display: block;
          color: $optional-color;
          text-transform: uppercase;

          margin: {
            top: -2px;
            bottom: 5px;
          }

          font: {
            size: 12px;
          }
        }

        .title {
          margin-bottom: 0;
          line-height: 1.4;

          font: {
            size: 17px;
            weight: 700;
          }

          a {
            display: inline-block;
          }
        }
      }

      &:hover {
        .thumb {

          &::before,
          &::after {
            opacity: 1;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }

  .widget_categories {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        overflow: hidden;
        color: $black-color;
        padding-left: 18px;

        font: {
          weight: 700;
          size: 15px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          background: $main-color;
          height: 8px;
          width: 8px;
          content: "";
          border-radius: 50%;
          left: 0;
          top: 7px;
          position: absolute;
        }

        a {
          color: $black-color;
          display: inline-block;

          &:hover {
            color: $main-color;
          }
        }

        // .post-count {
        //   color: $optional-color;
        //   font-size: 14px;
        //   display: inline-block;
        //   position: relative;
        //   top: 1px;
        // }
      }
    }
  }

  .widget_tag_cloud {
    .widget-title {
      margin-bottom: 17px;
    }
  }

  .tagcloud {
    a {
      display: inline-block;
      background: #f5f5f5;
      color: $black-color;
      padding: 7px 15px;
      border: none;
      border-radius: 3px;

      font: {
        weight: 700;
        size: 14px !important;
      }

      margin: {
        top: 8px;
        right: 4px;
      }

      &:hover,
      &:focus {
        color: $white-color;
        background-color: $main-color;
      }
    }
  }

  .widget_insight {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        margin-bottom: -1px;

        a {
          position: relative;
          display: block;
          padding: 15px 25px 15px 40px;
          color: $optional-color;
          border: 1px solid #eeeeee;

          font: {
            size: $font-size;
            weight: 700;
          }

          &::before {
            width: 5px;
            height: 5px;
            transition: $transition;
            background-color: $optional-color;
            content: "";
            position: absolute;
            left: 25px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
          }

          &:hover,
          &.active {
            border-color: $main-color;
            background-color: $main-color;
            color: $white-color;

            &::before {
              background-color: $white-color;
            }
          }
        }

        &.active {
          a {
            border-color: $main-color;
            background-color: $main-color;
            color: $white-color;

            &::before {
              background-color: $white-color;
            }
          }
        }
      }
    }
  }

  .widget_recent_courses {
    position: relative;
    overflow: hidden;

    .widget-title {
      margin-bottom: 30px;
    }

    .item {
      overflow: hidden;
      margin-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }

      .thumb {
        float: left;
        height: 80px;
        overflow: hidden;
        display: block;
        position: relative;
        width: 80px;
        margin-right: 15px;
        z-index: 1;

        .fullimage {
          width: 80px;
          height: 80px;
          display: inline-block;
          border-radius: 3px;

          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }

          &.bg1 {
            background-image: url(/images/courses/courses1.jpg);
          }

          &.bg2 {
            background-image: url(/images/courses/courses2.jpg);
          }

          &.bg3 {
            background-image: url(/images/courses/courses3.jpg);
          }
        }

        &::before,
        &::after {
          transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
          content: "";
          background-color: $white-color;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
        }

        &::before {
          width: 40px;
          height: 1px;
          left: 100%;
        }

        &::after {
          height: 40px;
          width: 1px;
          top: 0;
        }
      }

      .info {
        overflow: hidden;
        margin-top: 5px;

        span {
          display: block;
          color: $main-color;
          text-transform: uppercase;

          margin: {
            bottom: 6px;
            top: -5px;
          }

          font: {
            size: 18px;
            weight: 800;
            style: italic;
          }
        }

        .title {
          margin-bottom: 0;
          line-height: 1.4;

          font: {
            size: $font-size;
            weight: 700;
          }

          a {
            display: inline-block;
          }
        }
      }

      &:hover {
        .thumb {

          &::before,
          &::after {
            opacity: 1;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }

  .filter_by_price {
    .irs--flat {
      .irs-bar {
        background-color: $main-color;
      }

      .irs-handle>i:first-child {
        background-color: $main-color;
      }
    }
  }

  .widget_popular_products {
    position: relative;
    overflow: hidden;

    .widget-title {
      margin-bottom: 30px;
    }

    .item {
      overflow: hidden;
      margin-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }

      .thumb {
        float: left;
        height: 110px;
        overflow: hidden;
        display: block;
        position: relative;
        width: 110px;
        margin-right: 15px;
        z-index: 1;

        .fullimage {
          width: 110px;
          height: 110px;
          display: inline-block;
          border-radius: 3px;

          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }

          &.bg1 {
            background-image: url(/images/products/product7.jpg);
          }

          &.bg2 {
            background-image: url(/images/products/product8.jpg);
          }

          &.bg3 {
            background-image: url(/images/products/product9.jpg);
          }
        }

        &::before,
        &::after {
          transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
          content: "";
          background-color: $white-color;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
        }

        &::before {
          width: 40px;
          height: 1px;
          left: 100%;
        }

        &::after {
          height: 40px;
          width: 1px;
          top: 0;
        }
      }

      .info {
        overflow: hidden;
        margin-top: 5px;

        span {
          display: block;
          color: $main-color;
          text-transform: uppercase;

          margin: {
            bottom: 6px;
            top: -5px;
          }

          font: {
            size: 18px;
            weight: 800;
            style: italic;
          }
        }

        .title {
          margin-bottom: 0;
          line-height: 1.4;

          font: {
            size: 17px;
            weight: 700;
          }

          a {
            display: inline-block;
          }
        }

        .rating {
          margin-top: 8px;

          i {
            color: #f6b500;
            display: inline-block;
            margin-right: 2px;
          }
        }
      }

      &:hover {
        .thumb {

          &::before,
          &::after {
            opacity: 1;
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }
}

@media only #{$media} and ($feature_max : $value_two) {
  .about-video-box {
    margin: {
      top: 30px;
      left: 0;
    }

    .video-btn {
      width: 65px;
      height: 65px;
      font-size: 30px;
      line-height: 65px;
    }
  }

  .blog-details-desc {
    .article-content {
      .entry-meta {
        ul {
          margin-bottom: -13px;

          li {
            display: inline-block;
            border-right: none;
            font-size: 15px;

            margin: {
              bottom: 13px;
              right: 13px;
            }

            padding: {
              right: 0;
            }

            &:last-child {
              margin: {
                right: 0;
              }
            }
          }
        }
      }

      h3 {
        font-size: 19px;
      }
    }

    .article-footer {
      text-align: center;

      .article-tags {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .article-share {
        flex: 0 0 100%;
        max-width: 100%;

        .social {
          text-align: center;
          margin-top: 15px;
        }
      }
    }

    .article-author {
      .author-profile {
        .author-profile-title {
          .author-profile-title-details .author-profile-details h4 {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.blog-details-desc {
  .article-image {
    img {
      width: 100%;
    }
  }

  .article-image-slides {
    &.owl-theme {
      .owl-nav {
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
        transition: $transition;

        [class*="owl-"] {
          color: #87a1c1;
          font-size: 18px;
          margin: 0;
          padding: 0;
          background: $white-color;
          width: 45px;
          height: 45px;
          line-height: 40px;
          position: absolute;
          left: 20px;
          transition: $transition;
          top: 50%;
          transform: translateY(-50%);

          padding: {
            left: 5px !important;
          }

          border: {
            radius: 50%;
            style: solid;
            color: $white-color;
            width: 1.5px;
          }

          &.owl-next {
            left: auto;
            right: 20px;

            padding: {
              left: 3px !important;
            }
          }

          &:hover {
            background-color: $main-color;
            color: $white-color;
            border-color: $main-color;
          }
        }
      }

      &:hover {
        .owl-nav {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .article-video {
    iframe {
      width: 100%;
      height: 400px;
      border: none !important;
    }
  }

  .article-content {
    margin-top: 30px;

    .entry-meta {
      ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
          margin-right: 20px;
          position: relative;
          display: inline-block;
          border-right: 1px solid #eeeeee;

          font: {
            weight: 600;
            size: $font-size;
          }

          padding: {
            right: 20px;
            left: 45px;
          }

          i {
            font-size: 32px;
            color: #cfcfcf;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          span {
            display: block;
            color: $main-color;
            text-transform: uppercase;
            margin-bottom: 2px;

            font: {
              size: 13px;
              weight: 800;
            }
          }

          a {
            display: inline-block;

            font: {
              size: $font-size;
              weight: 800;
            }
          }

          &:last-child {
            padding-right: 0;
            margin-right: 0;
            border-right: none;
          }
        }
      }
    }

    h3 {
      margin: {
        bottom: 16px;
        top: 28px;
      }

      font: {
        size: 24px;
        weight: 800;
      }
    }

    .wp-block-gallery {
      &.columns-3 {
        padding-left: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;

        margin: {
          right: -10px;
          left: -10px;
          bottom: 30px;
          top: 30px;
        }

        li {
          flex: 0 0 33.3333%;
          max-width: 33.3333%;

          padding: {
            right: 10px;
            left: 10px;
          }

          figure {
            margin-bottom: 0;
          }
        }
      }
    }

    .features-list {
      padding-left: 0;
      list-style-type: none;

      margin: {
        top: 20px;
        bottom: 30px;
      }

      li {
        margin-bottom: 15px;
        position: relative;
        padding-left: 26px;
        color: $optional-color;

        i {
          color: $main-color;
          display: inline-block;
          font-size: 20px;
          position: absolute;
          left: 0;
          top: 1px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .article-footer {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #eeeeee;
    padding-top: 30px;

    margin: {
      top: 30px;
    }

    .article-tags {
      flex: 0 0 50%;
      max-width: 50%;
      color: $optional-color;

      span {
        display: inline-block;
        color: $black-color;
        margin-right: 3px;
        position: relative;
        top: 3px;

        font: {
          size: 20px;
        }
      }

      a {
        display: inline-block;

        font: {
          weight: 700;
        }

        position: relative;
        padding-right: 3px;
        margin-right: 3px;

        &::before {
          content: ",";
          position: absolute;
          right: 0;
        }

        &:last-child::before {
          display: none;
          padding-right: 0;
          margin-right: 0;
        }
      }
    }

    .article-share {
      flex: 0 0 50%;
      max-width: 50%;

      .social {
        padding-left: 0;
        list-style-type: none;
        text-align: right;

        margin: {
          bottom: 0;
        }

        li {
          display: inline-block;

          span {
            display: inline-block;
            margin-right: 3px;
            font-weight: 700;
            position: relative;
            top: -1px;
          }

          a {
            display: block;
            width: 32px;
            height: 32px;
            line-height: 34px;
            border-radius: 50%;
            background-color: $main-color;
            color: $white-color;
            border: 1px solid $main-color;
            text-align: center;
            font-size: 17px;
            margin-left: 5px;

            &:hover,
            &:focus {
              color: $main-color;
              background-color: transparent;
            }

            &.facebook {
              background-color: #3b5998;
              border-color: #3b5998;
              color: $white-color;

              &:hover,
              &:focus {
                color: #3b5998;
                background-color: transparent;
              }
            }

            &.twitter {
              background-color: #1da1f2;
              border-color: #1da1f2;
              color: $white-color;

              &:hover,
              &:focus {
                color: #1da1f2;
                background-color: transparent;
              }
            }

            &.linkedin {
              background-color: #007bb5;
              border-color: #007bb5;
              color: $white-color;

              &:hover,
              &:focus {
                color: #007bb5;
                background-color: transparent;
              }
            }

            &.instagram {
              background-color: #c13584;
              border-color: #c13584;
              color: $white-color;

              &:hover,
              &:focus {
                color: #c13584;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }

  .article-author {
    margin-top: 30px;
    border-radius: 5px;
    background: #fdfcfc;

    .author-profile-header {
      height: 115px;
      border-radius: 5px 5px 0 0;

      background: {
        color: $main-color;
        image: url(/images/bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
      }
    }

    .author-profile {
      padding: 0 25px 25px;

      .author-profile-title {
        position: relative;
        z-index: 1;

        margin: {
          bottom: 15px;
          top: -45px;
        }

        img {
          display: inline-block;
          border: 3px solid $white-color;
          width: 100px;
          height: 100px;
        }

        .author-profile-title-details {
          margin-top: 15px;

          .author-profile-details {
            h4 {
              margin-bottom: 5px;

              font: {
                size: 22px;
                weight: 800;
              }
            }

            span {
              color: $optional-color;
            }
          }
        }
      }
    }
  }
}

@media only #{$media} and ($feature_min : $value_one) and ($feature_max : $value_two) {
  .container {
    max-width: 540px;
  }

  .blog-details-desc {
    .article-footer {
      text-align: left;

      .article-tags {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .article-share {
        flex: 0 0 50%;
        max-width: 50%;

        .social {
          text-align: right;
          margin-top: 0;
        }
      }
    }
  }
}

@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {
  .blog-details-desc {
    .article-content {
      h3 {
        font-size: 20px;
      }
    }

    .article-author {
      .author-profile {
        .author-profile-title {
          .author-profile-title-details {
            .author-profile-details {
              h4 {
                font-size: 19px;
              }
            }
          }
        }
      }
    }
  }

  .about-video-box {
    margin: {
      left: 0;
      top: 30px;
    }
  }
}

@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_six) {
  .container {
    max-width: 960px;
  }

  .blog-details-desc {
    .article-content {
      h3 {
        font-size: 22px;
      }
    }

    .article-author {
      .author-profile {
        .author-profile-title {
          .author-profile-title-details {
            .author-profile-details {
              h4 {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}



@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}



.about-video-box {
  margin-left: 4px;
  position: relative;
  z-index: 1;
  border-radius: 5px;
}

.about-video-box .image {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.about-video-box .image::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #221638;
  opacity: 0.1;
  z-index: 1;
}

.about-video-box .image img {
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.about-video-box .video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  font-size: 35px;
  text-align: center;
  line-height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  background-color: #5172ff;
  border-radius: 50%;
  color: #ffffff;
}

.about-video-box .video-btn::after,
.about-video-box .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  border: 1px solid #5172ff;
}

.about-video-box .video-btn::before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}

.about-video-box .video-btn::after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

.about-video-box .video-btn:hover {
  color: #ffffff;
  background-color: #221638;
}

.about-video-box .video-btn:hover::before,
.about-video-box .video-btn:hover::after {
  border-color: #221638;
}

.about-video-box:hover .image img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

.about-video-box .shape10 {
  left: -6.5%;
  bottom: -13.5%;
  opacity: 0.5;
}