@import "../../styles/scss/index.scss";

.shape9 {
    position: absolute;
    left: 10%;
    bottom: 20%;
    z-index: -1;

    img {
        animation: {
            name: rotateme;
            duration: 10s;
            iteration-count: infinite;
            timing-function: linear;
        }
    }
}


.shape9 {
    position: absolute;
    left: 10%;
    bottom: 20%;
    z-index: -1;
}

.shape9 {
    img {
        -webkit-animation-name: rotateme;
        animation-name: rotateme;
        -webkit-animation-duration: 10s;
        animation-duration: 10s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
}

@media only screen and (max-width: 767px) {
    .shape9 {
        display: none;
    }

    .page-title-area {
        padding: {
            top: 15px;
            bottom: 50px;
        }
    }

    .page-title-content {
        ul {
            li {
                margin-right: 17px;
                font-size: 14px;

                margin: {
                    right: 17px;
                    bottom: 8px;
                }

                &::before {
                    right: -11px;
                    top: 4px;
                    height: 13px;
                }
            }
        }

        .h1 {
            max-width: 100%;
            margin-top: 30px;
            font-size: 26px !important;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .shape9 {
        display: none;
    }

    .page-title-content h2 {
        max-width: 720px;
        font-size: 30px;
    }

}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .shape9 {
        display: none;
    }
}

@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {
    .page-title-content {
        .h1 {

            max-width: 720px;
            font-size: 30px;
        }
    }
}

.page-title-area {
    background-color: #f8f9f8;
    position: relative;
    z-index: 1;

    padding: {
        top: 20px;
        bottom: 40px;
    }
}

.page-title-content {
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            display: inline-block;
            margin-right: 20px;
            position: relative;
            color: $black-color;

            font: {
                size: $font-size;
                weight: 600;
            }

            &::before {
                content: "";
                position: absolute;
                right: -10px;
                top: 5px;
                transform: rotate(10deg);
                background-color: #696969;
                height: 15px;
                width: 1px;
            }

            a {
                color: $optional-color;
                // display: block;
                font-weight: 500;

                &:hover {
                    color: $main-color;
                }
            }

            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
        }
    }

    .h1 {

        text-align: center;
        max-width: 800px;

        margin: {
            top: 30px;
            bottom: 0;
            left: auto;
            right: auto;
        }

        font: {
            size: 36px;
            weight: 800;
        }
    }

    .li {
        font {
            size: 14px;
        }
    }

}